import * as React from "react";
import { useParams } from "react-router-dom";
import { Heading, Para, ParaTitle } from "../style";
import { useTranslation } from "react-i18next";
import {
  FlexDiv,
  HeadingSecondary,
  NewsContainer,
  NewsContent,
  MainImg,
  FlexButtons,
  CopyButton,
  CardSingleInner,
  CardSingleWrapper,
} from "./style";
import { CustomAlert } from "../JobsList/style";
import Alert from "@mui/material/Alert";
import Markdown from "../Strapi/Markdown";

const NewsSingleMain = () => {
  const { t } = useTranslation();
  const { newsId } = useParams();

  const [showAlert, setShowAlert] = React.useState(false);
  const [item, setNewsItems] = React.useState([]);

  React.useEffect(() => {
    fetch(`${process.env.REACT_APP_CMSURL}/api/blogs/${newsId}?populate=*`)
      .then((response) => response.json())
      .then((data) => setNewsItems(data?.data ?? []));
  }, []);

  const copy = () => {
    navigator.clipboard.writeText(window.location.href);
    setShowAlert(true);
    window.setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };
  return (
    <>
      <NewsContainer>
        <CardSingleWrapper>
          <CardSingleInner>
            <MainImg
              src={`${process.env.REACT_APP_CMSURL}${item.Image?.url ?? ""}`}
              alt="news-single"
            />
          </CardSingleInner>
        </CardSingleWrapper>
        <NewsContent>
          <FlexDiv justifyContent="space-between">
            <FlexDiv>
              <img
                src={`${process.env.REACT_APP_CMSURL}${
                  item.AuthorImage?.url ?? ""
                }`}
                alt="business"
              />
              <div>
                <Para>{t("news.publishedBy")}</Para>
                <HeadingSecondary>{item.Author}</HeadingSecondary>
              </div>
            </FlexDiv>
            <Para>{item.Date}</Para>
          </FlexDiv>
          <Heading>{t(item.Title)}</Heading>
          <Markdown content={item.Content} />
          <ParaTitle>{t("newsSingle.share")}</ParaTitle>
          <FlexButtons>
            <CopyButton onClick={copy}>
              <img src="/images/copy.svg" alt="" />
              <Para>{t("newsSingle.copy")}</Para>
            </CopyButton>
            {/* <img src="/images/linkedin1.svg" alt="" />
            <img src="/images/facebook.svg" alt="" />
            <img src="/images/youtube.svg" alt="" />
            <img src="/images/telegram.svg" alt="" />*/}
          </FlexButtons>
        </NewsContent>
        <CustomAlert>
          {showAlert ? (
            <Alert severity="success" color="warning">
              Copied to clipboard
            </Alert>
          ) : (
            <></>
          )}
        </CustomAlert>
      </NewsContainer>
    </>
  );
};
export default NewsSingleMain;
