import React, { useState } from "react";
import { CustomiseCard } from "../style";
import {
  JobDescription,
  JobDescriptionPoint,
  JobDescriptionTitle,
  JobInfoWrapper,
  JobLink,
  JobPost,
  JobPostWrap,
  JobSocialButton,
  JobSocialMedia,
  JobTitle,
  CustomAlert,
} from "./style";
import { Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import { Share } from "@mui/icons-material";

const JobsList = (props) => {
  const { t } = useTranslation();
  const [showAlert, setShowAlert] = useState(false);
  const share = (id) => {
    navigator.clipboard.writeText(`${window.location.href}/${id}`);
    setShowAlert(true);
    window.setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };

  return (
    <>
      {props?.jobs.map((job, key) => (
        <CustomiseCard padding="24px" margin="0 0 16px 0" key={key}>
          <JobInfoWrapper>
            <JobTitle>{job.Title}</JobTitle>
            <JobSocialMedia>
              <JobSocialButton
                onClick={() => {
                  share(job.documentId);
                }}
                sx={{ mr: "10px" }}
              >
                <img
                  src="/images/TypoLiner.svg"
                  alt="not-found"
                  style={{ marginRight: "10px" }}
                />
                {t("career.jobs.socialBtn1")}
              </JobSocialButton>
              {/* <JobSocialButton>
                <img
                  src="/images/ArchiveLiner.svg"
                  alt="not-found"
                  style={{ marginRight: "10px" }}
                />
                {t("career.jobs.socialBtn2")}
              </JobSocialButton>*/}
            </JobSocialMedia>
          </JobInfoWrapper>
          <JobPost margin="16px 0 0 0">
            <JobPostWrap>
              <img src="/images/LocationLiner.svg" alt="not-found" />
              <span>{job?.Location?.Location}</span>
            </JobPostWrap>
            <JobPostWrap>
              <img src="/images/TimeLiner.svg" alt="not-found" />
              <span>{job.Date}</span>
            </JobPostWrap>
            <JobPostWrap>
              <img src="/images/ContentLiner.svg" alt="not-found" />
              <span>{job.JobCategory}</span>
            </JobPostWrap>
          </JobPost>
          <Divider
            variant="middle"
            sx={{ margin: "16px 0", border: "1px solid #F1F3F5" }}
          />
          <JobDescription>
            <JobDescriptionPoint>{job.Brief}</JobDescriptionPoint>
            <JobLink href={`/career/${job.documentId}`} underline="none">
              {t("career.jobs.jobLinkText")}
              <img src="/images/RightArrowLiner.svg" alt="not-found" />
            </JobLink>
          </JobDescription>
          <CustomAlert>
            {showAlert ? (
              <Alert severity="success" color="warning">
                Copied to clipboard
              </Alert>
            ) : (
              <></>
            )}
          </CustomAlert>
        </CustomiseCard>
      ))}
    </>
  );
};

export default JobsList;
